* {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* https://codepen.io/t_afif/pen/VwXOmjW */
.about-us-divider {
  --b: 6px;  /* control the border thickness */
  --w: 80px;  /* control the width of the line*/
  --g: 15px;  /* control the gap */
  --c: #0B486B;
  --p: 1em;  /* control padding */

  width: fit-content;
  padding: 0 var(--p);
  line-height: 1.6em;
  border: 1px solid;
  color: #fff;
  background:
    conic-gradient(from 45deg at left, var(--c) 25%, #0000 0) 0,
    conic-gradient(from -135deg at right, var(--c) 25%, #0000 0) 100%;
  background-size: 51% 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  border-image:
    linear-gradient(#0000 calc(50% - var(--b)/2),
      var(--c) 0 calc(50% + var(--b)/2),
      #0000 0) 1/0 var(--w)/calc(var(--w) + var(--g));
  margin-inline: auto;
}

.faded-background {
  --b: linear-gradient(black, #581845);  /* background-image */
  --o: 0.5;  /* opacity */

  height: 100%;
  position: relative;
}

.faded-background::before {
  content: "";
  background-image: var(--b);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: var(--o);
  z-index: -1;
}
